import React from "react";

import { ErrorBoundary } from "@elastic/react-search-ui";
import { Cassette, FilePerson, FileText, ShieldExclamation, PeopleFill, Building } from 'react-bootstrap-icons';
import Linkify from 'react-linkify';


export function bestMatchingLang(data, la) {
	if (data === undefined) return la;
	const langCntryCodes = Object.keys(data);
	if (langCntryCodes.includes(la)) return la;
	if (langCntryCodes.includes("de")) return "de";
	if (langCntryCodes.includes("en")) return "en";
	if (langCntryCodes.includes("nn")) return "nn";
	return langCntryCodes[0];
}

export function listgroup(values, label, icon, separator) {
	if (values === undefined || values === '' || values.length <= 0) return <div />
	return <div className="sui-result__details"> <h5> {icon}{label}</h5>{values.map((p,index)=> <span key={index}>{p.txt}{separator? separator: '\u00A0'}	</span>)}</div >
}
export function listrights(values, values2, label, icon, separator) {
    
    // Collect all the texts from both values and values2
    const allTexts = [
        ...(values?.map(p => p?.txt) ?? []),
        ...(values2?.map(p => p.txt) ?? [])
    ];
    
    // Remove duplicates by converting the array to a Set and then back to an array
    const uniqueTexts = [...new Set(allTexts)];
    
    // Return the JSX with the unique values
    return (
        <div className="sui-result__details">
            <h5>{icon}{label}</h5>
            {uniqueTexts.map((txt, index) => (
                <span key={index}>
                    {txt}{separator ? separator : '\u00A0'}
                </span>
            ))}
        </div>
    );
}

export function listgroupul(values, label, icon, separator) {
	if (values === undefined || values === '' || values.length <= 0) return <div />
	return <Linkify><ul className="sui-result__details"> <h5> {icon}{label}</h5>{values.map((p,index)=> <li key={index}>{p.txt}{separator? separator: '\u00A0'}	</li>)}</ul ></Linkify>
}

function CustomResultView({ result, onClickLink }) {
	const pid = result._meta.rawHit._source.id;
	console.info(result._meta.rawHit._source.id)
	console.info(result._meta.rawHit._source['dcterms.tableOfContents'])
	return (
		<ErrorBoundary>
			<li className="sui-result" id={"z-"+pid}>
				<div className="sui-result__header">
					<h3><Cassette />&nbsp;
						<a title="Help us to fix broken links. Send us a message, if you find broken links or know alternative locations of the resources." href={result._meta.rawHit._source.id} target="_rs">{result._meta.rawHit._source['dc.title'][0].txt}</a>
					</h3><span style={{color: "white"}} >.</span>
				</div>
				<div className="sui-result__body">
					<div className="sui-result-container">
					{listgroup(result._meta.rawHit._source['dc.description'],"Description",<FileText/>)}
					{listgroup(result._meta.rawHit._source['dc.creator'],"Creator",<FilePerson/>)}
					{listgroup(result._meta.rawHit._source['dc.contributor'],"Contributor",<PeopleFill/>)}
					{listgroup(result._meta.rawHit._source['dc.publisher'],"Publisher",<Building/>)}
					{listgroup(result._meta.rawHit._source['dcterms.alternative'],"Alternative",<FileText/>)}
					{listgroupul(result._meta.rawHit._source['dcterms.tableOfContents'],"TableofContents",<FileText/>)}
					{listrights(result._meta.rawHit._source['dc.rights'],result._meta.rawHit._source['dcterms.accessRights'],"License",<ShieldExclamation/>)}
					</div>
				</div>
			</li>
		</ErrorBoundary>
	);
}
export default CustomResultView;


